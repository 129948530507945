body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Montserrat', Arial, sans-serif;
  -webkit-user-select: none; 
  -moz-user-select: none;    
  -ms-user-select: none;    
  user-select: none;      
}

.background {
  height: 100vh;
  background-image: url('https://images.pexels.com/photos/19969240/pexels-photo-19969240.jpeg?cs=srgb&dl=pexels-masoodaslami-19969240.jpg&fm=jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: #fff;
  padding: 20px;
}

.huge-text {
  font-size: 10em; 
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin: 0;
  animation: flyAway 3s forwards;
}